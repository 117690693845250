<template>
  <div>
    <b>{{ $vuetify.lang.t("$vuetify.manager.menu_" + menuType) }}</b>
    {{ $vuetify.lang.t("$vuetify.manager.menu_editor") }}
    <v-divider class="mt-3 mb-3" />

    <SimpleMenu v-if="menuType != 'main'" :menuType = menuType />
    <NestedMenu v-if="menuType == 'main'" :menuType = menuType />

  </div>
</template>
<script>
import SimpleMenu from "@/views/manager/site/menu/Simple";
import NestedMenu from "@/views/manager/site/menu/Nested";

export default {
  components: {
    SimpleMenu,
    NestedMenu
  },
  data() {
    return {
      menuType: this.$route.params.position,
    };
  },
  watch: {
    $route() {
      this.menuType = this.$route.params.position;
    },
  },
  methods: {
    setMaxId(menuData) {
      let maxId = 0;
      if (menuData.length == 0) return maxId;
      for (const property in menuData) {
        if (maxId < menuData[property].id) {
          maxId = menuData[property].id;
        }
        if (menuData[property]["nested"]) {
          for (const child in menuData[property]["nested"]) {
            if (maxId < menuData[property]["nested"][child].id) {
              maxId = menuData[property]["nested"][child].id;
            }
          }
        }
      }

      return maxId;
    },
    getIndexById(menuData, parentId, childId) {
      if (parentId && typeof childId === "undefined") {
        return menuData
          .map(function (e) {
            return e.id;
          }).indexOf(parentId);
      }

      return menuData[parentId]["nested"]
        .map(function (e) {
          return e.id;
        }).indexOf(childId);
    },
  }
};
</script>
<template>
  <div>
    <v-btn @click="addMenuPopup = ++maxId" v-if="menuData">
      <v-icon>mdi-plus</v-icon>
      {{ $vuetify.lang.t("$vuetify.manager.add_object") }}
    </v-btn>
    <table style="table-layout: auto; width: 100%" class="mt-3">
      <draggable v-model="menuData" @end="onEnd" :group="{ name: 'g0' }">
        <transition-group>
          <div
            v-for="item in menuData"
            :key="item.id"
            class="draggableItem"
            :maxId="maxId < item.id ? item.id : maxId"
          >
            <tr v-if="(typeof item.type) != 'undefined'" class="dotted">
              <td>
                <v-icon color="primary" class="mr-3">mdi-drag-vertical</v-icon>
              </td>
              <td width="100%">
                <template v-if="item.type == 'textorlink'">
                  <template v-if="item.url == null || item.url == ''">{{
                    item[$i18n.locale]
                  }}</template>
                  <template v-else>
                    <a :href="item.url">{{ item[$i18n.locale] }}</a>
                  </template>
                </template>
                <template v-else-if="item.type == 'module'">
                  {{ $i18n.messages[$i18n.locale]["site"][item.name] }}
                </template>
                <draggable
                  v-model="item.nested"
                  @end="onEnd"
                  :group="{ name: 'g' + item.id }"
                  class="mt-3"
                >
                  <transition-group>
                    <div
                      v-for="child in item.nested"
                      :key="child.id"
                      class="draggableItem"
                      :maxId="maxId < child.id ? child.id : maxId"
                    >
                      <tr>
                        <td>
                          <v-icon color="primary" class="mr-3"
                            >mdi-drag-vertical</v-icon
                          >
                        </td>
                        <td width="100%">
                          <template v-if="child.type == 'textorlink'">
                            <template
                              v-if="child.url == null || child.url == ''"
                              >{{ child[$i18n.locale] }}</template
                            >
                            <template v-else>
                              <a :href="child.url">{{ child[$i18n.locale] }}</a>
                            </template>
                          </template>
                          <template v-else-if="child.type == 'module'">
                            {{
                              $i18n.messages[$i18n.locale]["site"][child.name]
                            }}
                          </template>
                        </td>
                        <td>
                          <v-switch
                            v-model="child.enabled"
                            @change="hasChanged = true"
                          ></v-switch>
                        </td>
                        <td>
                          <v-icon
                            v-if="child.type != 'module'"
                            left
                            @click="popupEdit(child, $parent.getIndexById(menuData, item.id))"
                            >mdi-pencil</v-icon
                          >
                        </td>
                        <td>
                          <v-icon
                            v-if="child.type != 'module'"
                            left
                            @click="popupDelete([child, $parent.getIndexById(menuData, item.id)])"
                            >mdi-delete</v-icon
                          >
                        </td>
                      </tr>
                    </div>
                  </transition-group>
                </draggable>
              </td>
              <td>
                <v-icon
                  v-if="item.type != 'module'"
                  left
                  @click="addSubMenu(item.id)"
                  >mdi-plus</v-icon
                >
              </td>
              <td>
                <v-switch
                  v-model="item.enabled"
                  @change="hasChanged = true"
                ></v-switch>
              </td>
              <td>
                <v-icon
                  v-if="item.type != 'module'"
                  left
                  @click="popupEdit(item)"
                  >mdi-pencil</v-icon
                >
              </td>
              <td>
                <v-icon
                  v-if="item.type != 'module' && menuData.length > 2"
                  left
                  @click="popupDelete(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </div>
        </transition-group>
      </draggable>
    </table>
    <v-btn
      class="float-right mt-3"
      v-if="hasChanged"
      @click="saveMenu"
      :loading="saveMenuLoading"
    >
      <v-icon class="mr-3">mdi-content-save</v-icon>
      {{ $vuetify.lang.t("$vuetify.action.save") }}
    </v-btn>
    <AddMenuItem
      :popupAddMenuItem="addMenuPopup"
      v-on:closePopup="closeAddPopup"
    />
    <EditMenu
      :popupEditMenu="editMenuPopup"
      :menuItem="editableItem"
      v-on:closePopup="closePopup"
    />
    <ConfirmRemoval :confirmRemoval="confirmRemoval" :item="itemToRemove" />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import AddMenuItem from "@/components/manager/popup/site/AddMenuItem";
import EditMenu from "@/components/manager/popup/site/EditMenu";
import ConfirmRemoval from "@/components/manager/popup/site/ConfirmRemoval.vue";
import Api from "@/services/api";
export default {
  components: {
    draggable,
    AddMenuItem,
    EditMenu,
    ConfirmRemoval,
  },
  data() {
    return {
      menuData: [],
      saveMenuLoading: false,
      maxId: 0,
      hasChanged: false,
      editMenuPopup: false,
      editableItem: false,
      itemToRemove: false,
      addMenuPopup: false,
      confirmRemoval: false,
    };
  },
  created: function () {
    this.getData();
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.menuType = this.$route.params.position;
      this.saveMenuLoading = false;
      this.hasChanged = false;
      Api.menu("get", this.menuType).then(({ data }) => {
        this.menuData = data;
        this.maxId = this.$parent.setMaxId(this.menuData);
      });
    },
    closePopup(item, parentItemIdx) {
      if (!item) {
        this.editMenuPopup = false;
        this.hasChanged = false;
        return;
      }

      if (parentItemIdx === false || typeof parentItemIdx === "undefined") {
        this.menuData[this.$parent.getIndexById(this.menuData, item.id)] = item;
      } else {
        this.menuData[parentItemIdx]["nested"][
          this.$parent.getIndexById(this.menuData, parentItemIdx, item.id)
        ] = item;
      }
      this.editMenuPopup = false;
      this.hasChanged = true;
      this.maxId = this.$parent.setMaxId(this.menuData);
    },
    removeItem(item) {
      if (!item[1]) {
        this.menuData = this.menuData.filter(function (el) {
          return el.id !== item.id;
        });
      } else {
        const filteredList = this.menuData[item[1]]["nested"].filter(
          (element) => {
            return element.id != item[0].id;
          }
        );
        this.menuData[item[1]]["nested"] = filteredList;
      }
      this.hasChanged = true;
      this.itemToRemove = false;
      this.confirmRemoval = false;
      this.setMaxId();
    },
    popupEdit(item, parentIdx) {
      this.editableItem = [item, parentIdx];
      this.editMenuPopup = true;
    },
    popupDelete(item) {
      this.itemToRemove = item;
      this.confirmRemoval = true;
    },
    closeAddPopup(item, parentIdx) {
      this.addMenuPopup = false;
      if (item) {
        if(parentIdx) {
          this.menuData[parentIdx]['nested'].push(item);
        } else {
          item.nested = [];
          this.menuData.push(item);
        }
        this.hasChanged = true;
      }
      this.maxId = this.$parent.setMaxId(this.menuData);
    },
    addSubMenu(itemId) {
      this.addMenuPopup = [++this.maxId,this.$parent.getIndexById(this.menuData,itemId)]
    },
    onEnd() {
      this.hasChanged = true;
    },
    saveMenu() {
      this.saveMenuLoading = true;
      Api.menu("set", this.menuType, this.menuData).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.saveMenuLoading = false;
        this.hasChanged = false;
      });
    },
  },
};
</script>
<style scoped>
.draggableItem {
  border: 1px dashed #c0c0c0;
  padding: 20px 10px;
}
</style>
import vm from '@/main.js'
import * as HelperModel from '@/models/siteMenu.js'

class SiteMenuHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(data) {
        var validator = this.validatorModel()

        if ((data.type == 'text' || data.type == 'url') && (data.ka === null || data.ka.length < 1)) {
            validator.ka.error_show = true;
            validator.ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.ka.error_show = false;
            validator.ka.error_msg = null;
            validator.hasError = false;
        }

        if ((data.type == 'text' || data.type == 'url')  && (data.en === null || data.en.length < 1)) {
            validator.en.error_show = true;
            validator.en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.en.error_show = false;
            validator.en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        var urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        if (data.type == 'url' && (data.url === null || data.url.length < 1 || !urlRegex.test(data.url))) {
            validator.url.error_show = true;
            validator.url.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.url.error_show = false;
            validator.url.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        return validator;
    }

}

const SiteMenuHelper = new SiteMenuHelpers
export default SiteMenuHelper
export const validator = {
    hasError: true,
    ka: {
        error_show: false,
        error_msg: null
    },
    en: {
        error_show: false,
        error_msg: null
    },
    url: {
        error_show: false,
        error_msg: null
    },
}

export const newItem = {
    ka: null,
    en: null,
    url: null
}
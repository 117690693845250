<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.edit_menu") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.text_ka') + ' *'"
                v-model="menuItemData.ka"
                :error="validator.ka.error_show"
                :error-messages="validator.ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.text_en') + ' *'"
                v-model="menuItemData.en"
                :error="validator.en.error_show"
                :error-messages="validator.en.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.url')"
                v-model="menuItemData.url"
                :error="validator.url.error_show"
                :error-messages="validator.url.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup(false)">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="closePopup(menuItemData)" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SiteMenuHelper from "@/helpers/siteMenu";
export default {
  data: function () {
    return {
      dialogState: false,
      itemData: false,
      validator: SiteMenuHelper.validatorModel(),
      menuItemData: false,
      parentItemId: false
    };
  },
  methods: {
    validateForm() {
      this.validator = SiteMenuHelper.validateForm(this.menuItemData);
    },
    closePopup(item) {
      this.dialogState = false;
      this.$parent.closePopup(item, this.parentItemId);
    },
  },
  watch: {
    popupEditMenu(newVal) {
      this.dialogState = newVal;
    },
    menuItem(newVal) {
      this.menuItemData = JSON.parse(JSON.stringify(newVal));
      if(typeof newVal[0] !== 'undefined') {
        this.parentItemId = newVal[1]
        this.menuItemData = JSON.parse(JSON.stringify(newVal[0]));
      }
    },
  },
  props: ["popupEditMenu", "menuItem"],
};
</script>
<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-plus</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.add_object") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.text_ka') + ' *'"
                v-model="newItem.ka"
                :error="validator.ka.error_show"
                :error-messages="validator.ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.text_en') + ' *'"
                v-model="newItem.en"
                :error="validator.en.error_show"
                :error-messages="validator.en.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.url')"
                v-model="newItem.url"
                :error="validator.url.error_show"
                :error-messages="validator.url.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup(false)">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="closePopup(newItem)" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-plus</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SiteMenuHelper from "@/helpers/siteMenu";
export default {
  data: function () {
    return {
      dialogState: false,
      itemData: false,
      validator: SiteMenuHelper.validatorModel(),
      newItem: SiteMenuHelper.newItemModel(),
    };
  },
  methods: {
    validateForm() {
      this.newItem.type = "textorlink";
      this.newItem.enabled = true;
      this.newItem.id =
        typeof this.popupAddMenuItem[0] != "undefined"
          ? this.popupAddMenuItem[0]
          : this.popupAddMenuItem;
      this.validator = SiteMenuHelper.validateForm(this.newItem);
    },
    closePopup(item) {
      if (item) item.type = "textorlink";
      this.dialogState = false;
      this.$parent.closeAddPopup(item, this.popupAddMenuItem[1] ?? null);
    },
  },
  watch: {
    popupAddMenuItem(newVal) {
      this.dialogState = newVal;
      this.newItem = SiteMenuHelper.newItemModel();
    },
  },
  props: ["popupAddMenuItem"],
};
</script>